const letterAcepted = "Letra aceptada";
const letterPending = "Letra pendiente de aceptación";
const creditNote = "Nota Crédito";
const debitNote = "Nota Débito";
const creditNote_ = "Nota de credito"
const debitNote_ = "Nota de debito"
const protestedLetter = "Letra Protestada"
const ticket  = "Boleta"
const claim = "Reclamo"
const balanceFavor  = "Saldo a favor"
const debitBalance = "Saldo Deudor"
const advance = "Anticipo"
const invoice = "Factura"


const statesAccount = "cuenta";
const reportsInvoice = "factura";
const paymentsHistory = "historico";

const statusAccount = "accountStatus"
const statusReportsInvoice = "importBilling"
const statusPaymentsHistory = "paymentHistory"
const statusLetter = "letter"

export {
  letterAcepted,
  creditNote_,
  debitNote_,
  statusLetter,
  statusAccount,
  statusReportsInvoice,
  statusPaymentsHistory,
  letterPending,
  creditNote,
  debitNote,
  statesAccount,
  reportsInvoice,
  paymentsHistory,
  protestedLetter,
  ticket,
  claim,
  balanceFavor,
  debitBalance,
  advance,
  invoice
};
