<template>
  <nav class="nav text-white mt-2">
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'homeMarketing' }"
      @click="gtmView()"
    >
      <li
        class="pointer item"
        :class="[path === 'estados-cuenta' ? 'li-active' : '']"
      >
        <i class="fas fa-upload"></i>Estado de cuenta
      </li>
    </router-link>
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'historyPayments' }"
      @click="gtmView()"
    >
      <li
        class="pointer item"
        :class="[path === 'historial-pagos' ? 'li-active' : '']"
      >
        <i class="far fa-money-bill-alt"></i>Histórico de pagos
      </li>
    </router-link>
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'creditBeraus' }"
      @click="gtmView()"
    >
      <li
        class="pointer item"
        :class="[path === 'centrales-de-riesgo' ? 'li-active' : '']"
      >
        <i class="fas fa-hand-holding-usd"></i>Centrales de Riesgo
      </li>
    </router-link>
  </nav>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  methods: {
    gtmView() {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "UA-249712648-1");
      gtag("event", "page_view", {
        page_title: "<Page Title>",
        page_location: window.location,
        page_path: "<Page Path>",
        send_to: "<GA_MEASUREMENT_ID>",
      });
      console.log("page change");
    },
  },
};
</script>
