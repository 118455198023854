<template>
  <nav class="nav text-white mt-2">
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'homeAdmin' }"
      @click="gtmView()"
    >
      <li
        class="pointer item"
        :class="[path === 'subida-archivos' ? 'li-active' : '']"
      >
        <i class="fas fa-upload"></i>Subir Archivos
      </li>
    </router-link>
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'digitalLetters' }"
      @click="gtmView()"
    >
      <li
        class="pointer item"
        :class="[path === 'letras-digitales' ? 'li-active' : '']"
      >
        <i class="far fa-money-bill-alt"></i>Letras Digitales
      </li>
    </router-link>
    <router-link
      class="font_helvetica_neue75_bold"
      :to="{ name: 'clients' }"
      @click="gtmView()"
    >
      <li class="pointer item" :class="[path === 'clients' ? 'li-active' : '']">
        <i class="fa fa-users"></i>Clientes
      </li>
    </router-link>
    
  </nav>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  methods: {
    gtmView() {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "UA-249712648-1");
      gtag("event", "page_view", {
        page_title: "<Page Title>",
        page_location: window.location,
        page_path: "<Page Path>",
        send_to: "<GA_MEASUREMENT_ID>",
      });
      console.log("page change");
    },
  },
};
</script>
