<template>
  <div class="return_admin" v-if="adminView">
    <span>
      <i class="far fa-eye"></i>
      Vista maestro
    </span>
    <button class="btn btn-primary" @click="returnAdmin">Regresar al Admin</button>
  </div>
  <main class="cms-dashboard" :class="{ 'open-sidebar': showNavMobile }">
    <header class="header-navbar align-items-center mx-2 h-content mt-2">
      <button class="btn btn-secondary btn-menu" @click="showNavMobile = !showNavMobile">
        <i class="fas fa-bars" v-if="!showNavMobile"></i>
        <i class="fas fa-times" v-if="showNavMobile"></i>
      </button>
      <h2 class="mx-1 font_helvetica_neue75_bold text-gray-150">
        {{ getTitleNav() }}
      </h2>
    </header>
    <aside class="cms-dashboard-sidebar bg-sidebar scroll pb-3 p-relative"
      v-bind:class="{ 'show-sidebar': showNavMobile }">
      <div class="header text-center mt-2">
        <div v-if="auth.rol !== roleAdmin && !rolesMarketing.includes(auth.rol)" @click="$router.push('mi-perfil')"
          class="
            d-inline-flex
            align-items-center
            justify-center
            profile-mobile
            bg-white
            mb-1
            pointer
          ">
          <i class="middle text-gray-150 fas fa-user-circle font-s-h1"></i>

          <span class="text-gray-150 font_helvetica_neue75_bold business">{{
              user.company_name
          }}</span>
        </div>
        <p class="text-white font_helvetica_neue75_bold">
          {{ $t('cms-dashboard.companySelected') }}
        </p>
        <keep-alive>
          <div class="d-flex justify-center user-dashboard-logos w-100 mt-2">
            <template v-if="!rolesMarketing.includes(auth.rol)">
              <div class="w-50 pointer">
                <img class="w-auto p-1" src="/images/decorations/fc.png" alt="faber"
                  @click="(e) => changeCompany('faber')" :class="selectCompany === 'faber' ? 'selected' : ''" />
                <p class="text-white font_helvetica_neue75_bold">{{ $t('cms-dashboard.companies.faber') }}</p>
              </div>
              <div class="w-50 pointer">
                <img class="w-auto p-1" src="/images/decorations/vk.png" alt="Vikingo"
                  @click="(e) => changeCompany('vikingo')" :class="selectCompany === 'vikingo' ? 'selected' : ''" />
                <p class="text-white font_helvetica_neue75_bold">{{ $t('cms-dashboard.companies.vikingo') }}</p>
              </div>
            </template>
            <template v-else>
              <div class="w-50" v-if="user.company_name === 'faber'">
                <img class="w-auto p-1 selected" src="/images/decorations/fc.png" alt="faber" />
                <p class="text-white font_helvetica_neue75_bold">{{ $t('cms-dashboard.companies.faber') }}</p>
              </div>
              <div class="w-50" v-else>
                <img class="w-auto p-1 selected" src="/images/decorations/vk.png" alt="Vikingo" />
                <p class="text-white font_helvetica_neue75_bold">{{ $t('cms-dashboard.companies.vikingo') }}</p>
              </div>
            </template>
          </div>
        </keep-alive>
      </div>
      <nav-admin v-if="auth.rol === roleAdmin" :path="path" />
      <nav-user v-if="roleUser.includes(auth.rol)" :path="path" />
      <nav-marketing v-if="rolesMarketing.includes(auth.rol)" :path="path" />

      <div class="sidebar-footer p-absolute">
        <span class="
            font_helvetica_neue75_bold
            pointer
            text-white
            d-flex
            align-items-center
            gap-1
          " @click="logout">
          <i class="fas fa-sign-out-alt"></i>
          {{ $t('cms-dashboard.footer.logout') }}
        </span>
      </div>
    </aside>
    <slot></slot>
  </main>
</template>


<script>
import useSelectCompany from '@/modules/user/composables/ui/useSelectCompany';
import useSelectNav from '@/modules/user/composables/ui/useSelectNav';
import { useStore } from 'vuex';
import useBilling from '@/modules/user/composables/Dashboard/useBilling';
import { useRouter } from 'vue-router';
import useAuth from '@/modules/shared/composables/useAuth';
import NavUser from './NavUser';
import NavAdmin from './NavAdmin';
import NavMarketing from './NavMarketing';
import {ref} from 'vue'

import { computed, KeepAlive } from 'vue';
export default {
  components: { KeepAlive, NavUser, NavAdmin , NavMarketing },
  created() {
    let company = localStorage.getItem('company');
    let rol = localStorage.getItem('rol');
    if (company && company !== 'null') {
      this.setSelectCompany(company);
      return;
    } else {
      this.setSelectCompany("faber");
    }
    if(rol === "2" || rol === "3")
      this.push('/usuario');
    if(rol === "1")
      this.push({ name: 'homeAdmin' });
    if(rol === "5")
      this.push({name:"homeMarketing"});
  },
  setup() {
    const store = useStore();
    const { push } = useRouter();
    const adminView = ref(localStorage.getItem('admin-data') || null)

    const logout = () => {
      store.commit('auth/logout');
      push('/');
    };

    return {
      ...useAuth(),
      ...useSelectCompany(),
      ...useSelectNav(),
      ...store.state.user,
      ...useBilling(),
      user: computed(() => store.getters['user/getInfoUser']),
      logout,
      push,
      adminView
    };
  },
  methods: {
    changeCompany(company) {
      const { rol } = this.$store.getters["auth/getAuth"];
      this.setSelectCompany(company);
      if (this.roleUser.includes(rol)) {
        this.getStates();
        this.clear();
        this.getAccountData();
        this.getBillingData();
      }
    },
    returnAdmin(){
      if(this.adminView){
        const adminData = JSON.parse(localStorage.getItem('admin-data'))
        this.$store.commit('auth/loginUser', {
          token:adminData.token,
          rol: adminData.role,
          id: adminData.id
        },{ root: true })
        localStorage.removeItem('admin-data')
        this.push('/admin/clients')
        // window.location.reload();
      }
    }
  },
};
</script>
<style lang="scss">
.user-dashboard-logos img {
  max-height: 120px;
  height: 100%;
  max-width: 120px;
  width: 100%;
}
</style>
