import { ref } from 'vue';
import { useRoute } from 'vue-router';
const useSelectNav = () => {
  let router = useRoute();
  let path = router.path.split('/')[2];
  let showNavMobile = ref(false);

  const getSelectNav = (route) => {
    return path === route ? 'li-active' : '';
  };

  const getTitleNav = () => {
    return router.meta.title;
  };

  return {
    getSelectNav,
    path,
    getTitleNav,
    showNavMobile,
  };
};

export default useSelectNav;
