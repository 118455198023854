import { ref , computed } from "vue";
import { useStore } from "vuex";
import getDate from "../../../../utils/dates/getDate";
import { letterAcepted , letterPending } from '../../../shared/constants/documents'

const useBilling = () => {
  const isLoading = ref(false);

  const store = useStore();
  const dateAccount = ref([]);
  const dateBilling = ref([]);
  const isSearch = ref(false);
  const pagebillingAct = ref(1);
  const pageAccountAct = ref(1);
  const billingPages = ref([1]);
  const accountPages = ref([1]);
  let isShow = ref(false);
  let typeModal = ref("result");
  let type = ref("success");
  const messageForm = ref({
    message: "",
    messageType: "",
  });
  const dates = ref({
    account: {
      startDate: "",
      endDate: "",
    },
    billing: {
      startDate: "",
      endDate: "",
    },
  });
  const getInfoUser = computed(() => {
    return store.getters["user/getInfoUser"]
  })
  const company = computed(() => {
    return store.getters["user/getCompany"]
  })
  const isDesktop = ref(true);
  const isMobileOrDesktop = () => {
    setInterval(() => {
      if (window.innerWidth > 900) {
        isDesktop.value = true;
      } else {
        isDesktop.value = false;
      }
    }, 100);
    return isDesktop;
  };

  const getStates = async () => {
    await store.dispatch("user/getAccountLastUpdate");
    await store.dispatch("user/getBillingLastUpdate");
  };

  const setAccountDates = () => {
    isSearch.value = true;
    if (dateAccount.value) {
      if (dateAccount.value.length === 0) {
        dates.value.account.startDate = "";
        dates.value.account.endDate = "";
      } else {
        dates.value.account.startDate = getDate(new Date(dateAccount.value[0]));
        !dateAccount.value[1]
          ? (dates.value.account.endDate = dates.value.account.startDate)
          : (dates.value.account.endDate = getDate(
              new Date(dateAccount.value[1])
            ));
      }
    } else if (!dateAccount.value) {
      if (!dateAccount.value) {
        dates.value.account.startDate = "";
        dates.value.account.endDate = "";
      }
    }
    getAccountData();
  };

  const setBillingDates = () => {
    isSearch.value = true;
    if (dateBilling.value) {
      if (dateBilling.value.length === 0) {
        dates.value.billing.startDate = "";
        dates.value.billing.endDate = "";
      } else {
        dates.value.billing.startDate = getDate(new Date(dateBilling.value[0]));
        !dateBilling.value[1]
          ? (dates.value.billing.endDate = dates.value.billing.startDate)
          : (dates.value.billing.endDate = getDate(
              new Date(dateBilling.value[1])
            ));
      }
    } else if (!dateBilling.value) {
      dates.value.billing.startDate = "";
      dates.value.billing.endDate = "";
    }
    getBillingData();
  };

  const getAccountData = async () => {
    isLoading.value = true;
    await store.dispatch("user/getAccountStatusData", {
      start_date: dates.value.account.startDate,
      end_date: dates.value.account.endDate,
      page: pageAccountAct.value,
    });

    isLoading.value = false;
    setTimeout(() => {
      scrollTop();
    }, 100);
  };

  const getBillingData = async () => {
    isLoading.value = true;
    await store.dispatch("user/getBillingData", {
      start_date: dates.value.billing.startDate,
      end_date: dates.value.billing.endDate,
      page: pagebillingAct.value,
    });
    isLoading.value = false;
    isLoading.value = false;
    setTimeout(() => {
      scrollTop();
    }, 100);
  };

  const setAccountPages = () => {
    if (store.state.user.billing.accountState.lastPage > 1) {
      if (
        store.state.user.billing.accountState.lastPage == pageAccountAct.value
      ) {
        accountPages.value = [pageAccountAct.value - 1, pageAccountAct.value];
        return;
      }
      if (pageAccountAct.value > 1) {
        accountPages.value = [
          pageAccountAct.value - 1,
          pageAccountAct.value,
          pageAccountAct.value + 1,
        ];
        return;
      }
      if (store.state.user.billing.accountState.lastPage == 2) {
        accountPages.value = [pageAccountAct.value, pageAccountAct.value + 1];
        return;
      }
      accountPages.value = [pageAccountAct.value, pageAccountAct.value + 1];
    }
  };

  const setBillingPages = () => {
    if (store.state.user.billing.billingReport.lastPage > 1) {
      if (
        store.state.user.billing.billingReport.lastPage == pagebillingAct.value
      ) {
        billingPages.value = [pagebillingAct.value - 1, pagebillingAct.value];
        return;
      }
      if (pagebillingAct.value > 1) {
        billingPages.value = [
          pagebillingAct.value - 1,
          pagebillingAct.value,
          pagebillingAct.value + 1,
        ];
        return;
      }
      if (store.state.user.billing.billingReport.lastPage == 2) {
        billingPages.value = [pagebillingAct.value, pagebillingAct.value + 1];
        return;
      }
      billingPages.value = [pagebillingAct.value, pagebillingAct.value + 1];
    }
  };

  const setAccountPage = async (page, data) => {
    pageAccountAct.value = page;
    setAccountPages();
    dates.value.account.startDate = data.startDate;
    dates.value.account.endDate = data.endDate;
    await getAccountData();
  };

  const setBillingPage = async (page, data) => {
    pagebillingAct.value = page;
    setBillingPages();
    dates.value.billing.startDate = data.startDate;
    dates.value.billing.endDate = data.endDate;
    await getBillingData();
  };

  const clear = (table = "") => {
    if (table === "statusAccount") {
      dateAccount.value = [];
      setAccountDates();
    } else {
      dateBilling.value = [];
      setBillingDates();
    }

    isSearch.value = false;
  };

  const scrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const getDocument = async (idDocument) => {
    const { data } = store.getters["user/getAccountState"];
    const letter = data.data.find((letter) => letter.id === idDocument);
    letter.isLoadingPDF = true;
    const { success, fileUrl } = await store.dispatch("admin/getDocument", {
      id: idDocument,
    });
    letter.isLoadingPDF = false;

    if (success) {
      await store.dispatch("user/getBillingData", {
        start_date: dates.value.billing.startDate,
        end_date: dates.value.billing.endDate,
        page: data.current_page,
      });
      let url = new URL(fileUrl);
      let link = document.createElement("a");
      link.setAttribute('target','_BLANK');
      link.setAttribute("href", url.href);
      
      link.setAttribute("download", "file");
      link.click()
    } else {
      messageForm.value.messageType = "error";
      isShow.value = true;
      messageForm.value.message = "Datos de letra vacío";
    }
  };

  const showOrClose = () =>{
    isShow.value = !isShow.value
  }

  return {
    clear,
    getStates,
    setAccountDates,
    setBillingDates,
    dateAccount,
    dateBilling,
    isDesktop,
    isMobileOrDesktop,
    pagebillingAct,
    showOrClose,
    getDocument,
    pageAccountAct,
    accountPages,
    billingPages,
    setAccountPages,
    setBillingPages,
    isLoading,
    getAccountData,
    getBillingData,
    setAccountPage,
    setBillingPage,
    dates,
    isSearch,
    letterAcepted,
    messageForm,
    company,
    isShow,
    type,
    typeModal,
    getInfoUser,
    letterPending,
  };
};

export default useBilling;
